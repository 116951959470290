.center {
    display: flex;
    justify-content: center;
}

.Contato {
    padding: 5% 0;
    background: linear-gradient(to top, #223564, #4e5669);
        /* background-image: url(../../assets/images/Fundo.png);
    background-position: center;
    background-size: cover; */

}

.Contato-card {
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 2%;
    /* background-color: white; */
    background-image: linear-gradient(-45deg, #ffffffd2, #91999ba9);
    border-radius: 20px;
}

.Contato-title h2 {
    font-size: 2em;
    color: black;
}

.Contato-title p {
    color: black;
    font-size: large;
    width: 80%;
    margin: 2% 10%;
}

label {
    color: black;
}

.Contato-inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 5%;
}

.inputs-left {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-nome {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 1% 2%;
}

.input-nome label {
    text-align: start;
    padding-left: 15px;
}

.input-nome input {
    border-radius: 5px;
    border: solid 1px #135463a2;
    padding: 2% 15px;
    display: flex;
    justify-content: center;
}

.input-nome input:focus {
    outline-color: #216aad;
}

.input-assunto {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 1% 2%;
}

.input-assunto label {
    text-align: start;
    padding-left: 15px;
}

.input-assunto input {
    border-radius: 5px;
    border: solid 1px #135463a2;
    padding: 2% 15px;
}

.input-assunto input:focus {
    outline-color: #216aad;
}

.input-mensagem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    padding: 2%;
    position: top;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.input-mensagem label {
    text-align: start;
    padding-left: 5px;
    padding-bottom: 2px;
}

.input-mensagem textarea {
    border-radius: 5px;
    border: solid 1px #135463a2;
    padding: 3% 15px;
    height: 25vh;
    width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.input-mensagem textarea::placeholder {
    position: absolute;
    top: 2;
    left: 5;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.input-mensagem textarea:focus {
    outline-color: #216aad;
}

.Contato-button {
    padding: 3% 0;
}

.Contato-button button {
    cursor: pointer;
    padding: 2% 3%;
    color: #0d3942;
    border: none;
    font-weight: bold;
    background-color: #dfeef165;
    border-radius: 10px;
    text-decoration: none;
    box-shadow: 3px 5px 5px rgb(122, 122, 122);
    transition: all 0.2s;
    border: 3px solid transparent;
}

.Contato-button button:hover {
    color: white;
    background-color: #135463;
    border: 3px solid #135463;
    transition: all 0.2s;
}

.Contato-button button:active {
    transition: 0.125s;
    box-shadow: 3px 5px 5px rgb(44, 44, 44);
    transform: translate(0px, 2px);
}

@media (min-width: 320px) and (max-width: 480px) {
    .Contato .center {
        padding: 5% 0 0 0;
    }

    .Contato .Contato-title {
        margin-top: 5%;
    }

    .Contato .Contato-title h2 {
        font-size: 1.5em;
    }

    .Contato .Contato-title p {
        font-size: medium;
    }

    .Contato .Contato-inputs {
        padding: 0;
    }
}

@media (max-width:1000px) {
    .Contato-inputs {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .inputs-left {
        width: 90%;
    }

    .input-mensagem {
        width: 80%;
    }
}