* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.PollList {
    min-height: 100vh;
    padding-top: 78.25px;
    background: #EEEEEE;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
}

.center {
    padding: 3% 10%;
}



.cardPesquisa {
    width: 100%;
    height: 100%;
    padding: 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.cardPesquisa .cardList {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    padding: 1%;
    background-color: #e3edf3e3;
    box-shadow: 4px 6px 2px 2px rgb(150, 140, 140);

}

.card-title h2 {
    color: #0d3a44;
    text-align: left;
}

.card-title {
    width: 70%;
    padding: 3% 5%;
    display: inline-block;
}

.blue {
    color: blue;
    text-align: left;
    padding-top: 5%;
}

.button-comecar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 100%;
}

.button-comecar button {
    cursor: pointer;
    padding: 5%;
    color: #135463;
    border: 3px solid #135463;
    background-color: transparent;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.5s;
    box-shadow: 3px 5px 5px rgb(122, 122, 122);
}

.button-comecar button:hover {
    color: white;
    background-color: #135463;
    border: 5px solid transparent;
    transition: 0.5s;
}

.button-comecar button:active {
    transition: 0.5s;
    box-shadow: 3px 5px 5px rgb(44, 44, 44);
    transform: translate(0px, 2px);
}

@media (max-width: 480px) {
    .PollList {
        padding-top: 105px;
    }

    .PollList .center {
        padding: 0;
        max-width: 100%;
    }

    .cardPesquisa .cardList {
        margin-bottom: 5%;
        flex-direction: column;
    }

    .cardList .button-comecar {
        width: 70%;
    }

    .card-title {
        width: 90%;
    }

    .card-title h2 {
        text-align: center;
    }

    .card-title p {
        text-align: center;
    }

    .info-title {
        font-size: .8em;
    }
}

@media (max-width: 600px) {
    .PollList {
        padding-top: 105px;
    }

    .PollList .center {
        max-width: 100%;
        width: 100%;
    }

    .cardPesquisa .cardList {
        margin-bottom: 5%;
        flex-direction: column;
    }

    .card-title {
        width: 90%;
    }

    .card-title h2 {
        text-align: center;
    }

    .card-title p {
        text-align: center;
    }

    .button-comecar {
        padding: 5% 0;
    }

    .info-title {
        font-size: 1em;
    }
}

@media (min-width: 601px) and (max-width: 767px) {

    .PollList .center {
        max-width: 100%;
        width: 100%;
    }

    .cardPesquisa .cardList {
        margin-bottom: 2%;
    }

    .info-title {
        font-size: 1em;
    }
}




.info-card {
    background-color: #e3edf3;
    width: 90%;
    max-height: fit-content;
    height: max-content;
    border-radius: 10px;
    padding: 5%;
    margin-top: 5%;
    margin-bottom: 30px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.3);
}

.info-title {
    font-size: 1.5em;
    text-align: center;
    color: #0d3a44;

    margin-bottom: 5%;
}

.button-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 100%;
}

.button-info button {
    cursor: pointer;
    padding: 5%;
    color: #135463;
    border: 3px solid #135463;
    background-color: transparent;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.5s;
    box-shadow: 3px 5px 5px rgb(122, 122, 122);
}

.button-info button:hover {
    color: white;
    background-color: #135463;
    transition: 0.5s;
}