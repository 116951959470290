.Social {
    color: white;
    background: -webkit-linear-gradient(left, #223564, #4e5669);
    background: -moz-linear-gradient(left, #223564, #4e5669);
    background: -ms-linear-gradient(left, #223564, #4e5669);
    background: -o-linear-gradient(left, #223564, #4e5669);
    background: linear-gradient(to right, #223564, #4e5669);
}

.social-participation {
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.social--text {
    width: 70%;
    margin-top: 20px;
    margin-bottom: 50px;
}

.social--text h2 {
    text-align: center;
    padding-bottom: 30px;
}

.social--text p {
    text-align: justify;
}

.social--img img {
    width: 350px;
    height: 300px;
}

@media (max-width:600px) {
    .social--text p {
        width: 100%;
        margin: 0;
        text-align: justify;
    }

    .social--text h2 {
        margin: 0;
        text-align: center;
    }
}