.Banner {
    padding-top: 80px;
    display: flex;
    background-image: url('../../../assets/images/fundo_macro_pouso.png');
    height: 95vh; 
    background-size: cover; /* Para cobrir todo o elemento com a imagem */
    background-repeat: no-repeat; /* Para evitar repetição da imagem */
}


.banner-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner-items img {
    display: flex;
    width: 100%;
}

.btn {
    width: fit-content;
    margin: 3% auto;
    padding: 10px;   
    /* background-color: #238ca3; */
    border: 3px solid transparent;
    border-radius: 5px;

}

.btn a {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    text-decoration: none;
    color: white;
}

.btn a:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.btn:hover a {
    padding-right: 25px;
}

.btn:hover a:after {
    opacity: 1;
    right: 0;
}

.carousel {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel .slide{
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel .control-dots {
    z-index: 0;
}

.image-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

@media (max-width: 768px) {
    .Banner {
        padding-top: 100px;
    }

    .center {
        max-width: 100%;
        padding: 0;
    }

    .btn {
        width: fit-content;
        margin: 3% auto;
    }
}