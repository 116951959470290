.productsContent {
    min-height: 100vh;
    padding-top: 78.25px;
    background: #EEEEEE;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
}

.productsTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productsTitle h1 {
    color: #000000;
    margin-top: 3%;
    /* text-shadow: 2px 2px 1px rgb(73, 68, 68); */
}
.productsTitle h2 {
    color: #000000;
    /* text-shadow: 2px 2px 1px rgb(73, 68, 68); */
}


.center {
    padding: 1% 10%;
}

.cardProduct {
    width: 100%;
    height: 100%;
    padding: 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

.cardProduct .cardList {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px 10px;
    border: none;
    padding: 1%;
    background-color: #e3edf3e3;
    box-shadow: 0px 6px 2px 0px rgb(150, 140, 140);
}



.cardTitle h2 {
    color: #0d3a44;
    text-align: left;
}

.cardTitle {
    width: 100%;
    padding: 20px;
    display: inline-block;
}

.cardContent {
    border-radius: 0px 0px 10px 10px;
    color: #005e72;
    display: flex;
    background-color: #e3edf3e3;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    max-height: 0vh;
    width: 80%;
    overflow: hidden;
}

.fileList .fileListItem {
    list-style-type: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px 5px;
}

.fileListItem a {
    color: #0d3a44;
    text-decoration: none;
}

.fileListItem a h4 {
    margin-left: 5px;
}

.fileListItem a:visited {
    color: #0d3a44;
}

.fileTitle {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.cardChevron {
    transform: rotate(180deg);
    transition-delay: 0s;
    transition: transform 0.2s ease-in-out;
}